import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getClientProjectQuests,
    getClientProjectWorkTimeReivew,
    getClientRepositories
} from 'src/api/apiCaller';
import { ClientTypes } from './slice';

export const SLICE_NAME = 'projects';

export const ACTION_TYPES = {
    SELECT_PROJECT: `${SLICE_NAME}/selectProject`,
    FETCH_CLIENT_REPOSITORIES: `${SLICE_NAME}/fetchClientRepositories`,
    FETCH_PROJECT_QUESTS: `${SLICE_NAME}/fetchProjectQuests`,
    FETCH_PROJECT_WORK_TIME_REVIEW: `${SLICE_NAME}/fetchProjectWorkTimeReview`
};

export const selectProject = createAsyncThunk(
    ACTION_TYPES.SELECT_PROJECT,
    async (id: string, { rejectWithValue }) => {
        try {
            return id;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const fetchClientRepositories = createAsyncThunk(
    ACTION_TYPES.FETCH_CLIENT_REPOSITORIES,
    async (_, { rejectWithValue }) => {
        try {
            const response = await getClientRepositories();
            return response;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const fetchProjectQuests = createAsyncThunk(
    ACTION_TYPES.FETCH_PROJECT_QUESTS,
    async (
        options: { page: number; resultsPerPage: number },
        { getState, rejectWithValue }
    ) => {
        try {
            const { client } = getState() as { client: ClientTypes };
            if (!client.selectedProjectId) {
                return;
            }
            const response = await getClientProjectQuests(
                client.selectedProjectId,
                options.page + 1,
                options.resultsPerPage
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const fetchProjectWorkTimeReview = createAsyncThunk(
    ACTION_TYPES.FETCH_PROJECT_WORK_TIME_REVIEW,
    async (_, { getState, rejectWithValue }) => {
        try {
            const { client } = getState() as { client: ClientTypes };
            const response = await getClientProjectWorkTimeReivew(
                client.selectedProjectId as string
            );
            return response.budgetReviews;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
