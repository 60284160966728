import { FunctionComponent } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Label, ProgressBar } from 'src/shared';
import { TooltipWrapper, tableStyles } from 'src/shared/Table/styled';
import { ClientQuest, QuestStatusMap } from 'src/types/client';
import { S } from './styled';
import {
    dateAndTimeSeperatedString,
    netTimeString
} from 'src/utils/dateFormat';
import { ReactComponent as AttachmentIcon } from 'src/assets/icons/attachment2-icon.svg';
import theme from 'src/theme';
export interface Props {
    clientQuest: ClientQuest;
}

const QuestRow: FunctionComponent<Props> = ({ clientQuest }: Props) => {
    return (
        <TableRow sx={tableStyles.dataRow}>
            <TableCell sx={tableStyles.muiTableCell}>
                <div style={S.verticallyCentered}>
                    <Label
                        component="span"
                        sxOverrides={{
                            ...tableStyles.baseLabel,
                            ...tableStyles.dataCell,
                            ...S.link
                        }}
                        onClick={() => {
                            window.open(`${clientQuest.trelloLink}`, '_blank');
                        }}
                    >
                        {clientQuest.quest.id}
                    </Label>
                </div>
            </TableCell>
            <TableCell sx={tableStyles.muiTableCell}>
                <div style={S.verticallyCentered}>
                    <Label
                        component="span"
                        sxOverrides={{
                            ...tableStyles.baseLabel,
                            ...tableStyles.dataCell
                        }}
                    >
                        {clientQuest.quest.title}
                    </Label>
                </div>
            </TableCell>
            <TableCell sx={tableStyles.muiTableCell}>
                <div style={S.verticallyCentered}>
                    <TooltipWrapper
                        title={
                            <Label sxOverrides={{ color: 'white' }}>
                                {clientQuest.quest.description}
                            </Label>
                        }
                        arrow
                    >
                        <div>
                            <Label
                                component="span"
                                sxOverrides={{
                                    ...tableStyles.baseLabel,
                                    ...tableStyles.dataCell
                                }}
                            >
                                {`${clientQuest.quest.description.substring(
                                    0,
                                    70
                                )}${
                                    clientQuest.quest.description.length > 70
                                        ? '...'
                                        : ''
                                }`}
                            </Label>
                        </div>
                    </TooltipWrapper>
                </div>
            </TableCell>
            <TableCell sx={tableStyles.muiTableCell}>
                <div style={S.verticallyCentered}>
                    <Label
                        component="span"
                        sxOverrides={{
                            ...tableStyles.baseLabel
                        }}
                    >
                        {
                            dateAndTimeSeperatedString(
                                new Date(clientQuest.quest.created)
                            ).date
                        }
                    </Label>
                    <Label
                        component="span"
                        sxOverrides={{
                            ...tableStyles.baseLabel,
                            ...tableStyles.dataCell
                        }}
                    >
                        {
                            dateAndTimeSeperatedString(
                                new Date(clientQuest.quest.created)
                            ).time
                        }
                    </Label>
                </div>
            </TableCell>
            <TableCell sx={tableStyles.muiTableCell}>
                <div style={S.verticallyCentered}>
                    <Label
                        component="span"
                        sxOverrides={{
                            ...tableStyles.baseLabel
                        }}
                    >
                        {netTimeString(clientQuest.netTime)}
                    </Label>
                </div>
            </TableCell>
            <TableCell sx={tableStyles.muiTableCell}>
                <div style={S.verticallyCentered}>
                    {Object.keys(clientQuest.quest.links || []).length ? (
                        <TooltipWrapper
                            title={
                                <div style={S.attachmentLinkWrapper}>
                                    {Object.entries(
                                        clientQuest.quest.links
                                    ).map(([key, value]) => (
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                window.open(value, '_blank');
                                            }}
                                        >
                                            {key}
                                        </div>
                                    ))}
                                </div>
                            }
                            arrow
                        >
                            <div style={S.attachmentLabelWrapper}>
                                <AttachmentIcon />
                                <Label
                                    component="span"
                                    sxOverrides={{
                                        ...tableStyles.yellowLabel
                                    }}
                                >
                                    {
                                        Object.keys(clientQuest.quest.links)
                                            .length
                                    }
                                </Label>
                            </div>
                        </TooltipWrapper>
                    ) : null}
                </div>
            </TableCell>
            <TableCell sx={tableStyles.muiTableCell}>
                <div style={S.verticallyCentered}>
                    {clientQuest.quest.status === 2 ? (
                        <>
                            <ProgressBar value={clientQuest.progress} />
                            {clientQuest.iteration > 1 ? (
                                <Label
                                    component="span"
                                    sxOverrides={{
                                        ...tableStyles.baseLabel
                                    }}
                                >
                                    {`Iteration ${clientQuest.iteration}`}
                                </Label>
                            ) : null}
                        </>
                    ) : (
                        <Label
                            sxOverrides={{
                                ...S.statusText,
                                color:
                                    clientQuest.quest.status === 4
                                        ? theme.color.brightGreen
                                        : theme.color.bluishCayan,
                                backgroundColor:
                                    clientQuest.quest.status === 4
                                        ? theme.color.darkMintGreen
                                        : theme.color.darkMintBlue
                            }}
                        >
                            {QuestStatusMap[clientQuest.quest.status - 1]}
                        </Label>
                    )}
                </div>
            </TableCell>
        </TableRow>
    );
};

export default QuestRow;
