import { ReactNode, FunctionComponent, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { StyledBox, ProfileIconBox, MenuListItem } from './styled';
import theme from 'src/theme';

export interface Props {
    icon: ReactNode;
    list: { option: string; onClick: () => void; selected?: boolean }[];
}

const DropdownWithIcon: FunctionComponent<Props> = ({ icon, list }) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <ProfileIconBox onClick={() => setOpen(!open)}>
                {icon}
            </ProfileIconBox>

            {open && (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <StyledBox>
                        <List>
                            <>
                                {list.map(
                                    ({ option, onClick, selected }, index) => {
                                        return (
                                            <MenuListItem
                                                key={index}
                                                disablePadding
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <ListItemButton
                                                    onClick={onClick}
                                                >
                                                    <ListItemText
                                                        primary={option}
                                                        style={{
                                                            color: selected
                                                                ? theme.color
                                                                      .lightningYellow
                                                                : 'white'
                                                        }}
                                                    />
                                                </ListItemButton>
                                            </MenuListItem>
                                        );
                                    }
                                )}
                            </>
                        </List>
                    </StyledBox>
                </ClickAwayListener>
            )}
        </>
    );
};

export default DropdownWithIcon;
