import { Box, ListItem } from '@mui/material';
import { styled } from '@mui/system';
import theme from '../../theme';

export const StyledBox = styled(Box)`
    border-radius: 2px;
    background-color: ${theme.color.darkBlue};
    color: ${theme.color.black};
    min-width: 120px;
    position: absolute;
    right: 50px;
    top: 58px;
`;

export const ProfileIconBox = styled(Box)`
    border-radius: 19px;
    width: 39px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.color.tuna};
    cursor: pointer;
`;

export const MenuListItem = styled(ListItem)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
