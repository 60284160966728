import { FunctionComponent, useEffect } from 'react';
import { Navbar, Loader, Label } from 'src/shared';
import {
    Root,
    SectionHeader,
    SectionHeaderLabels,
    SectionContentBox,
    S
} from './styled';
import Header from './components/ClientHeader';
import WorkTime from './components/WorkTime';
import Requests from './components/Requests';
import { ClientSelectors } from 'src/redux/client/selectors';
import { fetchProjectWorkTimeReview } from 'src/redux/client/service';
import useAppDispatch from 'src/hooks/useAppDispatch';

type Props = Record<string, never>;

const ClientDashboard: FunctionComponent<Props> = ({}: Props) => {
    const dispatch = useAppDispatch();
    const { isLoading, workTimeReview, selectedProjectId } = ClientSelectors();

    useEffect(() => {
        if (selectedProjectId) {
            dispatch(fetchProjectWorkTimeReview());
        }
    }, [dispatch, selectedProjectId]);

    return (
        <Navbar>
            {isLoading ? (
                <Loader />
            ) : (
                <Root>
                    <Header />
                    <SectionHeader>
                        <SectionHeaderLabels>
                            <Label sxOverrides={S.sectionTitle}>Requests</Label>
                            <Label sxOverrides={S.sectionSubtitle}>
                                The following is the history of all the features
                                that Beehive is developing
                            </Label>
                        </SectionHeaderLabels>
                    </SectionHeader>
                    <Requests />
                    <SectionHeader>
                        <SectionHeaderLabels>
                            <Label sxOverrides={S.sectionTitle}>
                                Total net work time for each month
                            </Label>
                            <Label sxOverrides={S.sectionSubtitle}>
                                This graph summarizes the total net work time
                                for each month for the last 6 months.
                            </Label>
                        </SectionHeaderLabels>
                    </SectionHeader>
                    <SectionContentBox>
                        <WorkTime data={workTimeReview} />
                    </SectionContentBox>
                </Root>
            )}
        </Navbar>
    );
};

export default ClientDashboard;
