import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import { styled } from '@mui/system';
import theme from '../../theme';

export const S = {
    waitingTasksLabel: {
        color: theme.color.white90,
        fontFamily: 'Inter',
        fontSize: '12px',
        cursor: 'pointer'
    },
    waitingTasksCount: {
        color: theme.color.darkBluebg,
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 500,
        padding: '6px',
        cursor: 'pointer'
    }
};

export const StyledAppBar = styled(AppBar)`
    background-color: ${theme.color.blackRock} !important;
    position: fixed;
`;

export const StyledToolbar = styled(Toolbar)`
    display: flex;
    justify-content: space-between;
    position: relative;
`;

export const ProfileItems = styled('div')``;

export const ProfileIconBox = styled(Box)`
    border-radius: 19px;
    width: 39px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.color.tuna};
    cursor: pointer;
`;

export const WaitingTasksBox = styled(Box)`
    height: 32px;
    display: flex;
    align-items: center;
    background-color: ${theme.color.chineseGold};
    border-radius: 30px;
    padding: 7px 6px 7px 12px;
    cursor: pointer;
`;
