import { IWorkHistory } from '../types/contributors';

export const CONTRIBUTORS_WORK_HISTORY: IWorkHistory[] = [
    {
        id: '1',
        name: 'Animate hand and allow swipe in last part of help page',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'solved',
        skills: ['React', 'React Native']
    },
    {
        id: '2',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '6',
        rating: 4.5,
        reviews: 2,
        status: 'in-progress',
        skills: ['React', 'React Native']
    },
    {
        id: '3',
        name: 'Create Dropdown component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'cancelled',
        skills: ['React', 'React Native']
    },
    {
        id: '4',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'cancelled',
        skills: ['React', 'React Native']
    },
    {
        id: '5',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'in-review',
        skills: ['React', 'React Native']
    },
    {
        id: '6',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '400$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'in-review',
        skills: ['React', 'React Native']
    },
    {
        id: '7',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '30 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'solved',
        skills: ['React', 'React Native']
    },
    {
        id: '8',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'solved',
        skills: ['React', 'React Native']
    },
    {
        id: '9',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'solved',
        skills: ['React', 'React Native']
    },
    {
        id: '10',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'solved',
        skills: ['React', 'React Native']
    },
    {
        id: '11',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'solved',
        skills: ['React', 'React Native']
    },
    {
        id: '12',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'solved',
        skills: ['React', 'React Native']
    },
    {
        id: '13',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'solved',
        skills: ['React', 'React Native']
    },
    {
        id: '14',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'solved',
        skills: ['React', 'React Native']
    },
    {
        id: '15',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'solved',
        skills: ['React', 'React Native']
    },
    {
        id: '16',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'solved',
        skills: ['React', 'React Native']
    },
    {
        id: '17',
        name: 'Create Work History component',
        project: 'Beehive',
        duration: '24 hour',
        price: '300$',
        iteration: '4',
        rating: 4.5,
        reviews: 2,
        status: 'solved',
        skills: ['React', 'React Native']
    }
];
