import React, { useState, FunctionComponent } from 'react';
import Label from '../Label';
import S from './styled';
import MuiSwitch from '@mui/material/Switch';
import { ThemeProvider } from '@mui/material/styles';

export interface Props {
    checked?: boolean;
    onchange?: () => void;
    label: string;
}

const Switch: FunctionComponent<Props> = ({ label, onchange, checked }) => {
    const [isChecked, setIsChecked] = useState(checked);

    function handleChange() {
        setIsChecked(!isChecked);
        if (onchange) {
            onchange();
        }
    }

    return (
        <S.SwitchInputBox>
            <S.LabelContainer>
                <Label sxOverrides={S.SwitchInputText}>{label}</Label>
            </S.LabelContainer>
            <S.SwitchContainer>
                <Label
                    sxOverrides={
                        isChecked ? S.SwitchInputSwitchedSX : S.SwitchInputSX
                    }
                >
                    Off
                </Label>
                <ThemeProvider theme={S.SwitchStyled}>
                    <MuiSwitch checked={isChecked} onChange={handleChange} />
                </ThemeProvider>
                <Label
                    sxOverrides={
                        !isChecked ? S.SwitchInputSwitchedSX : S.SwitchInputSX
                    }
                >
                    On
                </Label>
            </S.SwitchContainer>
        </S.SwitchInputBox>
    );
};

export default Switch;
