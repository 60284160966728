import randomDate from 'src/utils/dateFormat/RandomDate';
import { AdminNotesType, IContributor } from '../types/contributors';
import Avatar from 'src/assets/images/avatar.png';

export const ADMIN_NOTES: AdminNotesType[] = [...Array(20).keys()].map((i) => {
    return {
        id: i,
        adminName: 'John Doe ' + i,
        title: 'Animate hand and allow swipe in last part of help page',
        date: randomDate(new Date(2023, 1, 1), new Date()),
        note: "I'm going to write an amazing feedback on some king who once did a really good job. He liked to take care of everyone and write code on a very high level. He also paid attention to the details and did his job with full responsibility",
        edit: false
    };
});

const randomInteger = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1) + min);

const randomHour = (start: number, end: number) =>
    new Date(Date.now() - 3600000 * randomInteger(start, end));

const randomFloat = (min: number, max: number) =>
    (Math.random() * (max - min + 1) + min).toFixed(2);

const randomCountry = () => ['US', 'UN', 'GB', 'FR', 'CA'][randomInteger(0, 4)];

const randomName = () =>
    ['Joe Bidan', 'Donald Trump', 'Barack Obama', 'George W. Bush'][
        randomInteger(0, 3)
    ];

const randomSkill = () =>
    [['React'], ['React', 'Python'], ['React', 'Python', 'React Native']][
        randomInteger(0, 2)
    ];

const randomActive = () => [true, false][randomInteger(0, 1)];

export const CONTRIBUTORS: IContributor[] = [...Array(110).keys()].map((i) => {
    const contributor: any = {
        id: i + 1,
        name: randomName(),
        country: randomCountry(),
        active: randomActive(),
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: randomInteger(1, 10),
        work_in_review: randomInteger(1, 10),
        hourly_rate: randomFloat(10, 100),
        skills: randomSkill(),
        weekly_hours: randomHour(1, 50)
    };

    if (i % randomInteger(0, 109) === 0) delete contributor['active'];

    return contributor;
});

export const _CONTRIBUTORS = [
    {
        id: '1',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '2',
        name: 'John Lennon',
        country: 'UN',
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '3',
        name: 'John Lennon',
        country: 'EG',
        active: false,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '4',
        name: 'John Lennon',
        country: 'FR',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '5',
        name: 'John Lennon',
        country: 'CA',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '6',
        name: 'John Lennon',
        country: 'GB',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '7',
        name: 'John Lennon',
        country: 'OO',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '8',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '9',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '10',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '11',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '12',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '13',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '14',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '15',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '16',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '17',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '18',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '19',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '20',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '21',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '22',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '23',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '24',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '25',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '26',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '27',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '28',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '29',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '30',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '31',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '32',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '33',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '34',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '35',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '36',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '37',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '38',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '39',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '40',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '41',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '42',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '43',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '44',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '45',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '46',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '47',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '48',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '49',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '50',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '51',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '52',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '53',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '54',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '55',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '56',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '57',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '58',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '59',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '60',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '61',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '62',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '63',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '64',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '65',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '66',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '67',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '68',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '69',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '70',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '71',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '72',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '73',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '74',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '75',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '76',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '77',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '78',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '79',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '80',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '81',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '82',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '83',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '84',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '85',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '86',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '87',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '88',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '89',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '90',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '91',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '92',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '93',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '94',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '95',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '96',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '97',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '98',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '99',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '100',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '101',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '102',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '103',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '104',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '105',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '106',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '107',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '108',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '109',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '110',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '111',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '112',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '113',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '114',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '115',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '116',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '117',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '118',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '119',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '120',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '121',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '122',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '123',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '124',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '125',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '126',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '127',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '128',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '129',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '130',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '131',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '132',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '133',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '134',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '135',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '136',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '137',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '138',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '139',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    },
    {
        id: '140',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: '3d 5h ago',
        last_engagement: 'Last engagement',
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native']
    }
];

export const CONTRIBUTOR: IContributor = {
    id: '1',
    name: 'King David',
    country: 'Israel',
    country_code: 'IL',
    active: true,
    last_work: new Date(),
    last_engagement: new Date(),
    reserved_work: 1,
    work_in_review: 1,
    hourly_rate: '40.5',
    skills: ['Python', 'React'],
    rating: 4.7,
    joining_date: '3/4/2019',
    links: [],
    reviews: 242,
    local_time: '3:15 PM',
    weekly_hours: 40,
    image: Avatar
};
