import styled from '@emotion/styled';
import { Box } from '@mui/material';
import theme from 'src/theme';
import { createTheme } from '@mui/material/styles';

const S = {
    SwitchContainer: styled(Box)``,
    SwitchInputBox: styled(Box)`
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
        border: solid;
        border-color: ${theme.color.tuna};
        border-radius: 4px;
        justify-content: center;
        width: 100%;
        height: 56px;
        border-width: 0.1px;
        margin-top: 12px,
        margin-bottom: 12px,
    `,
    SwitchInputText: {
        font: '12px/12px "Inter",sans-serif !important',
        color: theme.color.white50,
        marginRight: '6px'
    },
    SwitchInputSX: {
        color: theme.color.white90,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 15
    },
    SwitchInputSwitchedSX: {
        color: theme.color.lightningYellow,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 15
    },
    LabelContainer: styled('div')({
        position: 'absolute',
        zIndex: 1,
        top: '-8px',
        left: '6px',
        size: '12px',
        lineHeight: '12px',
        backgroundColor: theme.color.codGray,
        borderWidth: '0.1px',
        paddingLeft: '4px'
    }),
    SwitchStyled: createTheme({
        components: {
            MuiSwitch: {
                styleOverrides: {
                    root: {
                        margin: 22 // Adjust the margin value as per your requirement
                    },
                    colorPrimary: {
                        '&.Mui-checked': {
                            // Controls checked color for the thumb
                            color: theme.color.lightningYellow
                        }
                    },
                    track: {
                        // Controls default (unchecked) color for the track
                        backgroundColor: theme.color.white,
                        '.Mui-checked.Mui-checked + &': {
                            // Controls checked color for the track
                            backgroundColor: theme.color.chineseGold
                        }
                    }
                }
            }
        }
    })
};

export default S;
