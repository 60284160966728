import { ICommunityContributor } from 'src/types/community-contributor';
import randomDate from 'src/utils/dateFormat/RandomDate';

const randomInteger = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1) + min);

const randomHour = (start: number, end: number) =>
    new Date(Date.now() - 3600000 * randomInteger(start, end));
export const COMMUNITY_CONTRIBUTORS: ICommunityContributor[] = [
    {
        id: '1',
        name: 'John Lennon',
        country: 'GB',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 1,
        work_in_review: 2,
        hourly_rate: '38.7',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.88,
        rating: 1
    },
    {
        id: '2',
        name: 'Mahatama Gandhi',
        country: 'IN',
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 3,
        work_in_review: 1,
        hourly_rate: '386',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.56,
        rating: 2
    },
    {
        id: '3',
        name: 'Natalie Portman',
        country: 'IL',
        active: false,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 5,
        work_in_review: 1,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.55,
        rating: 3
    },
    {
        id: '4',
        name: 'Bob Marley',
        country: 'JM',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 3,
        work_in_review: 5,
        hourly_rate: '8.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.1,
        rating: 4
    },
    {
        id: '5',
        name: 'Kristina Dubovskaya',
        country: 'BY',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 1,
        work_in_review: 8,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.4,
        rating: 5
    },
    {
        id: '6',
        name: 'John Lennon',
        country: 'GB',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 13,
        work_in_review: 12,
        hourly_rate: '34.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.55,
        rating: 3
    },
    {
        id: '7',
        name: 'John Lennon',
        country: 'OO',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 5,
        work_in_review: 6,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 1,
        rating: 2
    },
    {
        id: '8',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 7,
        work_in_review: 7,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.82,
        rating: 4
    },
    {
        id: '9',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 8,
        work_in_review: 9,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.7,
        rating: 1
    },
    {
        id: '10',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 1,
        work_in_review: 2,
        hourly_rate: '28.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.26,
        rating: 5
    },
    {
        id: '11',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.3,
        rating: 3
    },
    {
        id: '12',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '18.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.28,
        rating: 3
    },
    {
        id: '13',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 3,
        work_in_review: 5,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.85,
        rating: 5
    },
    {
        id: '14',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 6,
        work_in_review: 8,
        hourly_rate: '28.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.45,
        rating: 1
    },
    {
        id: '15',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 5,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.54,
        rating: 3
    },
    {
        id: '16',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 1,
        work_in_review: 7,
        hourly_rate: '36',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.11,
        rating: 2
    },
    {
        id: '17',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 8,
        work_in_review: 5,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.96,
        rating: 1
    },
    {
        id: '18',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 1,
        work_in_review: 3,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.5,
        rating: 5
    },
    {
        id: '19',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 1,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.08,
        rating: 3
    },
    {
        id: '20',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '3.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.01,
        rating: 1
    },
    {
        id: '21',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.22,
        rating: 4
    },
    {
        id: '22',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '68.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.61,
        rating: 3
    },
    {
        id: '23',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.76,
        rating: 2
    },
    {
        id: '24',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.82,
        rating: 1
    },
    {
        id: '25',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.11,
        rating: 5
    },
    {
        id: '26',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '32.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.65,
        rating: 2
    },
    {
        id: '27',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.16,
        rating: 2
    },
    {
        id: '28',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.03,
        rating: 2
    },
    {
        id: '29',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.11,
        rating: 3
    },
    {
        id: '30',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.67,
        rating: 4
    },
    {
        id: '31',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.13,
        rating: 1
    },
    {
        id: '32',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.68,
        rating: 3
    },
    {
        id: '33',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.09,
        rating: 5
    },
    {
        id: '34',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.77,
        rating: 2
    },
    {
        id: '35',
        name: 'John Lennon',
        country: 'US',
        active: true,
        last_work: randomDate(new Date(2023, 1, 1), new Date()),
        last_engagement: randomHour(1, 24),
        reserved_work: 2,
        work_in_review: 4,
        hourly_rate: '38.6',
        skills: ['React', 'Python', 'React Native'],
        weekly_availability: randomHour(1, 24),
        projects: ['Atomic', 'Mastering Diabetes', 'Cyberillium'],
        billable_hours_ratio: 0.74,
        rating: 2
    }
];
