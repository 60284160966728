import { FunctionComponent, useCallback, useRef, useState } from 'react';
import { Label } from 'src/shared';
import {
    CustomLabel,
    RawInput,
    RichTextLabel,
    SxDescriptionTask,
    SxTitle,
    TaskDescription,
    Title,
    Wrapper,
    RichTextSwitch
} from './styled';
import color from 'src/theme/color';
import { Formik } from 'formik';
import useAppDispatch from 'src/hooks/useAppDispatch';
import { getDescriptionAnalysis } from 'src/redux/delegate/service';
import MDEditor from '@uiw/react-md-editor';

interface Props {
    taskTitle: string | null;
    taskDescription: string | null;
    handleDataChanged: (taskTitle: string, taskDescription: string) => void;
}

const Delegate: FunctionComponent<Props> = ({
    taskTitle,
    taskDescription,
    handleDataChanged
}: Props) => {
    const dispatch = useAppDispatch();
    var ref = useRef(null);
    const [richTextEditorSwitch, setRichTextEditorSwitch] =
        useState<boolean>(false);

    const onSubmit = (values: any, { setSubmitting }: any) => {
        setSubmitting(false);
        getTaskDescriptionFeedback(values.title, values.description);
    };

    const getTaskDescriptionFeedback = useCallback(
        async (title: string, description: string) => {
            await dispatch(getDescriptionAnalysis({ title, description }));
        },
        [dispatch]
    );

    return (
        <Wrapper>
            <Formik
                innerRef={ref}
                initialValues={{
                    title: taskTitle || '',
                    description: taskDescription || ''
                }}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                validate={(values) => {
                    const errors = { title: '', description: '' };
                    if (!values.title) {
                        errors.title = 'This field is required';
                    }
                    if (!values.description) {
                        errors.description = 'This field is required';
                    }
                    if (errors.title || errors.description) {
                        return errors;
                    }
                    return;
                }}
                onSubmit={onSubmit}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Title>
                            <CustomLabel>&nbsp;Task title&nbsp;</CustomLabel>
                            <RawInput
                                id="outlined-helperText"
                                label="Task title&nbsp;"
                                name="title"
                                onChange={(e) => {
                                    handleChange(e);
                                    handleDataChanged(
                                        e.target.value,
                                        values.description || ''
                                    );
                                }}
                                onBlur={handleBlur}
                                value={values.title}
                                multiline
                                rows={1}
                                InputProps={{
                                    sx: SxTitle
                                }}
                                inputerror={errors.title}
                            />
                            {errors.title && (
                                <Label color="red">{errors.title}</Label>
                            )}
                        </Title>
                        <TaskDescription>
                            <CustomLabel>
                                &nbsp;Task description&nbsp;
                            </CustomLabel>
                            <RawInput
                                id="outlined-helperText"
                                label="Task description&nbsp;"
                                name="description"
                                onChange={(e) => {
                                    handleChange(e);
                                    handleDataChanged(
                                        values.title || '',
                                        e.target.value
                                    );
                                }}
                                onBlur={handleBlur}
                                value={values.description}
                                multiline
                                rows={20}
                                InputProps={{
                                    sx: SxDescriptionTask
                                }}
                                inputerror={errors.description}
                            />
                            {errors.description && (
                                <Label color="red">{errors.description}</Label>
                            )}
                            <RichTextLabel>
                                &nbsp;Rich text editor&nbsp;
                            </RichTextLabel>
                            <RichTextSwitch
                                checked={richTextEditorSwitch}
                                onChange={(e, checked) =>
                                    setRichTextEditorSwitch(checked)
                                }
                            />
                            {richTextEditorSwitch && (
                                <div>
                                    <MDEditor
                                        style={{
                                            backgroundColor: color.darkBlue
                                        }}
                                        value={values.description}
                                        height="100%"
                                        minHeight={300}
                                        preview={'edit'}
                                        visibleDragbar={false}
                                        onChange={(v) => {
                                            handleDataChanged(
                                                values.title || '',
                                                v || ''
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </TaskDescription>
                    </form>
                )}
            </Formik>
        </Wrapper>
    );
};

export default Delegate;
