import { styled } from '@mui/system';
import { Props } from './index';
import theme from '../../theme';

export const StyledButton = styled('button')(
    ({ width, height, color, borderRadius, styles, disabled }: Props) => ({
        alignItems: 'center',
        background: color,
        borderRadius: borderRadius || '6px',
        border: 'none',
        color: theme.color.white,
        display: 'flex',
        height: height || '34px',
        justifyContent: 'center',
        width: width || '100%',
        cursor: disabled ? 'auto' : 'pointer',
        ...styles
    })
);
