import {
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { tableStyles } from 'src/shared/Table/styled';
import QuestTableHeader from './QuestTableHeader';
import QuestRow from './QuestRow';
import useAppDispatch from 'src/hooks/useAppDispatch';
import { fetchProjectQuests } from 'src/redux/client/service';
import { ClientSelectors } from 'src/redux/client/selectors';

export interface Props {}

export const QUESTS_INITIAL_ROWS_PER_PAGE = 5;

const Requests: FunctionComponent<Props> = ({}: Props) => {
    const dispatch = useAppDispatch();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        QUESTS_INITIAL_ROWS_PER_PAGE
    );
    const { quests, totalQuests, selectedProjectId } = ClientSelectors();

    const handleChangePage = useCallback(
        (
            _event: React.MouseEvent<HTMLButtonElement> | null,
            pageChanged: number
        ) => {
            dispatch(
                fetchProjectQuests({
                    page: pageChanged,
                    resultsPerPage: rowsPerPage
                })
            )
                .unwrap()
                .then(() => {
                    setPage(pageChanged);
                });
        },
        [dispatch, setPage, rowsPerPage]
    );

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newRowsPerPage = parseInt(event.target.value);
            dispatch(
                fetchProjectQuests({ page: 0, resultsPerPage: newRowsPerPage })
            )
                .unwrap()
                .then(() => {
                    setRowsPerPage(newRowsPerPage);
                    setPage(0);
                });
        },
        [dispatch, setPage, setRowsPerPage]
    );

    const pagedQuests = useMemo(() => {
        return quests
            ? quests.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            : [];
    }, [quests, rowsPerPage, page]);

    // lazy initial fetch
    useEffect(() => {
        // lazy fetch first page only in case a the project was changed
        if (
            !quests ||
            (quests !== null &&
                quests.length &&
                String(quests[0].quest.projectId) !== String(selectedProjectId))
        ) {
            dispatch(
                fetchProjectQuests({ page: 0, resultsPerPage: rowsPerPage })
            );
        }
    }, [dispatch, selectedProjectId, rowsPerPage, quests]);

    return (
        <Box sx={tableStyles.root}>
            <TableContainer component={Paper} sx={tableStyles.container}>
                <Table
                    stickyHeader
                    sx={tableStyles.table}
                    aria-label="simple table"
                >
                    <QuestTableHeader />
                    <TableBody style={tableStyles.body}>
                        {pagedQuests.map((q, i) => (
                            <QuestRow clientQuest={q} key={i} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                sx={{
                    ...tableStyles.pagination,
                    ...tableStyles.paginationLabel,
                    ' svg': tableStyles.caret,
                    '& .MuiTablePagination-select': tableStyles.paginationSelect
                }}
                count={totalQuests || 0}
                page={page}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default Requests;
